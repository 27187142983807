// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-cancellation-form-index-jsx": () => import("./../../../src/pages/cancellation-form/index.jsx" /* webpackChunkName: "component---src-pages-cancellation-form-index-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-storno-index-jsx": () => import("./../../../src/pages/storno/index.jsx" /* webpackChunkName: "component---src-pages-storno-index-jsx" */),
  "component---src-pages-tos-jsx": () => import("./../../../src/pages/tos.jsx" /* webpackChunkName: "component---src-pages-tos-jsx" */)
}

